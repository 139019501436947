import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from '../../fetch/axios';
import { useSnackbar } from "notistack";
import { useTranslation } from "../../hooks/useTranslation";

const RestablecerPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [emailValue, setEmail] = useState("");
  const { translate } = useTranslation();
  
  const EnviarReset = async e => {

    if(emailValue){
      try {
        const res = await axios.post(
          "/users/password-recovery",
          {
            email: emailValue,
          }
        );
        if (res.data.errors) {;
          enqueueSnackbar(res.data.message, { variant: "error" });
        } else {
          localStorage.setItem('token_recuperar_clave', res.data.token);
          localStorage.setItem('token_email', emailValue);
          navigate("/login-confirmar-recuperar")
        }
      } catch (error) {
        enqueueSnackbar(translate('login.error5'), { variant: "error" });
      }
    }else{
      enqueueSnackbar(translate('login.error4'), { variant: "error" });
    }
  };

  return (
    <>
      <div>
        {translate('recovery.enter')} .
      </div>
      <label className="FormData">
        <em className="RequiredData">{translate('login.email')}</em>
        <div className="FormInput">
          <input placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
        </div>
      </label>
      <div
        className="ButtonLightMedium"
        onClick={EnviarReset}
      >
        {translate('recovery.titleM')}
        <i className="icon-ArrowRight"></i>
      </div>
    </>
  );
};

export default RestablecerPassword;
