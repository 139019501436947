import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import instance from "../fetch/axios";
import { TokenContext } from "../context/TokenContext";
import { CartContext } from "../context/CardContext";
import LanguajeCurrency from "./LanguajeCurrency";
import { useTranslation } from "../hooks/useTranslation";

const Component = ({ wishlist }) => {

  const { getToken } = useContext(TokenContext);
  const { translate } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const { totalQuantity } = useContext(CartContext);
  const { search } = useParams();
  
  const handleSearch = () => {
    if (searchValue) {

      instance.get(`/products/search?search=${searchValue}&per_page=8`)
        .then(response => {
          if (response.data.products.length === 0) {
            navigate('/sin-resultados', {
              state: { searchTerm: searchValue }
            });
            return;
          }
         
          navigate('/resultados/'+searchValue)

        })
        .catch(() => {
          navigate('/sin-resultados', {
            state: { searchTerm: searchValue }
          });
        });
    } else {
      navigate('/sin-resultados');
    }
  };

  return (
    <header className="Column">

      <div className="HeaderFirst">
        <div
          className="ImageIcon ArtOnU"
          onClick={() => navigate("/home")}
        ></div>
        <div className="HeaderMenus">
          <label className="FormData">
            <div className="FormInputMedium">
              <input
                placeholder={translate('home.search')}
                type="search"
                value={search}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <i className="InputIcon icon-Search" onClick={handleSearch}></i>
            </div>
          </label>
          <div className="Bubbles Row">
            <div className="icon-User" onClick={() => navigate(getToken() ? "/mi-perfil" : "/login")}>
              {" "}
              {getToken() ? translate('my_account_') : translate('login.title')}
            </div>
            
            <div className="icon-Hearty" onClick={() => getToken() ? navigate("/wishlist") :navigate("/login")  }>
              {wishlist ? <div className="Bubble">{wishlist}</div> : ""}
            </div>
            <div
              className="icon-Trolley"
              onClick={() => navigate("/carrito")}
            >
              {(getToken() && totalQuantity>0) ? <div className="Bubble">{totalQuantity}</div> : ""}
            </div>
          </div>
        </div>
      </div>

      <LanguajeCurrency />
    </header>
  );

};

export default Component;
