import React from 'react';
import PodriaInteresarte from './PodriaInteresarte';
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../../hooks/useTranslation';

const Component = () => {

	const navigate = useNavigate();
	const {translate} = useTranslation();

	return (
		<>
			<div className={`CategoryTitle PerfilSection`}>
			<ul className="Breadcrumb">
				<li onClick={() => { navigate('/home') }} style={{ cursor: "pointer" }}>Home</li>
			</ul>
			</div>
			<section className='ResultadosZero'>
				<i className='icon-Magnifying'></i>
				<div className='Ups'></div>
				<b>{translate('collections.send5')} </b>
				<p>{translate('collections.send6')}<br />{translate('collections.send7')}</p>
			</section>
			<PodriaInteresarte />
		</>
	);
};

export default Component;