import React, { useContext, useEffect, useRef, useState } from "react";
import MigajasCategorias from "../../components/MigajasCategorias";
import { useNavigate, useParams } from "react-router-dom";
import axios from '../../fetch/axios';
import { LanguageCurrencyContext } from "../../context/LanguageCurrencyContext";
import { TokenContext } from "../../context/TokenContext";
import LoadingCard from "./LoadingCard";
import ProductoResultado from "./ProductoResultado";
import { useTranslation } from "../../hooks/useTranslation";

const ResultadosSubcategorias = () => {

  const { getToken } = useContext(TokenContext);
  const coleccionPosition = useRef(null);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [subcategory, setSubategory] = useState(null);
  const [colecciones, setColecciones] = useState([]);
  const [subcategories, setsubcategories] = useState([]);
  const [posicioncoll, setPosicioncoll] = useState(null);
  const [posicionsub, setPosicionsub] = useState(null);
  const { categoria, subcategoria } = useParams();
  const [sub,setSub] = useState(subcategoria);
  const { lang, currency } = useContext(LanguageCurrencyContext);
  const [productos, setProductos] = useState([]);
  const [pages, setPages] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const { translate } = useTranslation();
  const navigate = useNavigate();

  const fetchCategory = async () => {
    try {
      const responseca = await axios.get(`/categories/detail?uri=${categoria}` + `&lang=` + lang + `&currency=` + currency);
      setCategory(responseca.data);

      setsubcategories(responseca.data.subcategories)

      // Recorrer el array de subcategorias y encontrar la coincidencia
      const matchingSubcategory = responseca.data.subcategories.find(item => item.uri === subcategoria);

      // Si se encuentra una coincidencia, actualizar subcategoryName
      if (matchingSubcategory) {
        setSubategory(matchingSubcategory.name);
        setPosicionsub(matchingSubcategory.id)
        fetchColecciones(matchingSubcategory.id);

      } else {
        // Si no se encuentra una coincidencia, establecer subcategoryName en null o un valor por defecto
        setSubategory(null);
        fetchColecciones(null);
      }
    } catch (error) {
      console.error('Error fetching category details', error);
    }
  };

  const fetchColecciones = async (subCatId = null) => {
    try {
      const responseca = await axios.get(`/collections/list` + `?lang=` + lang + `&currency=` + currency + `&subcategory_id=` + subCatId);
      setColecciones(responseca.data);
    } catch (error) {
      console.error('Error fetching category details', error);
    }
  };

  const fetchProductos = async (colelccion=null,subc=null) => {
    let url = `/products/search?subcategory_uri=` + (subc ? subc :sub) + `&lang=` + lang + `&currency=` + currency + `&page=` + pageCurrent;

    if (colelccion !== null && colelccion !== undefined) {
      url += `&collection_id=${encodeURIComponent(colelccion)}`;
    }

    try {
      const response = await axios.get(url, {
        headers: { 'Authorization': 'Bearer ' + getToken() }
      });
      // Procesa la respuesta aquí
      setProductos(response.data.products);
      setPages(response.data.pages);
      setLoading(false)

    } catch (error) {
      console.error('Error al obtener los productos:', error);
      throw error;
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchCategory(),
        fetchProductos(),
      ]);
    };

    fetchData();
  }, [lang, currency, pageCurrent])


  function SetPositionColeccion(event) {
    const pos = ["Vertical", "Horizontal"];
    pos[2] = event.target.dataset.n.split(",");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][0]]}`)
      .classList.add("Selected");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][1]]}`)
      .classList.remove("Selected");
    coleccionPosition.current.classList.toggle("ColeccionHorizontal");
    coleccionPosition.current.classList.toggle("ColeccionVertical");
  }

  const handlePageChange = (newPage) => {
    setPageCurrent(newPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={pageCurrent === i ? 'Selected' : ''}
          style={pageCurrent === i ? {} : { "cursor": 'pointer' }}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }

    return (
      <section className="ColeccionPagination">
        <div style={{ "cursor": 'pointer' }}
          className="icon-CaretLeft"
          onClick={() => pageCurrent > 1 && handlePageChange(pageCurrent - 1)}
        ></div>
        <ul>{pageNumbers}</ul>
        <div style={{ "cursor": 'pointer' }}
          className="icon-CaretRight"
          onClick={() => pageCurrent < pages && handlePageChange(pageCurrent + 1)}
        ></div>
      </section>
    );
  };

  return (
    <>
      <MigajasCategorias
        nivel1={category ? category.name : 'loading...'}
        nivel2={subcategory ? subcategory : ''}
      />

      <section className="ColeccionMenu">

        <div className="ColeccionMenuItems">
          <div>

            {subcategories && (
              <div>
                <p>{translate('collections.send1')} </p>
                <ul>
                  {subcategories.map((sub) => (
                    <li
                      key={sub.id}
                      className={`${posicionsub == sub.id ? 'Selected' : ''}`}
                      onClick={() => {
                        setSub(sub.uri)
                        navigate(`/category/${categoria}/${sub.uri}`);
                        setSubategory(sub.name)
                        setPosicionsub(sub.id);
                        fetchColecciones(sub.id);
                        setPosicioncoll(false);
                        if(pageCurrent!=1){
                          setPageCurrent(1);
                        }else{
                          fetchProductos(null,sub.uri);
                        }
                        setLoading(true);
                      }}
                    >
                      {sub.name}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* {colecciones && (
              <div>
                <p>{translate('collections.send1')} </p>
                <ul>
                  <li className={`${!posicioncoll ? 'Selected' : ''}`}
                    onClick={() => {
                      setPosicioncoll(false);
                      fetchProductos();
                      setLoading(true);
                    }}
                  >{translate('collections.send2')}</li>
                  {colecciones.map((coleccion) => (
                    <li
                      key={coleccion.id}
                      className={`${posicioncoll == coleccion.id ? 'Selected' : ''}`}
                      onClick={() => {
                        setPosicioncoll(coleccion.id);
                        fetchProductos(coleccion.id);
                        setLoading(true);
                      }}
                    >
                      {coleccion.name}</li>
                  ))}
                </ul>
              </div>
            )} */}
          </div>

        </div>

        <div>
          <span
            className="icon-Vertical"
            data-n="0,1"
            onClick={SetPositionColeccion}
          >
            {translate('collections.send3')}
          </span>
          <span
            className="icon-Horizontal Selected"
            data-n="1,0"
            onClick={SetPositionColeccion}
          >
            {translate('collections.send4')}
          </span>
        </div>

      </section>

      <section
        className="ColeccionItems ColeccionVertical"
        ref={coleccionPosition}
      >
        {!loading ? productos.map((producto) => (
          <ProductoResultado
            key={producto.id || producto._id}
            producto={producto}
          />))
          :
          <>
            {<LoadingCard />}
          </>
        }

      </section>

      {pages > 1 && renderPagination()}


    </>
  );

};

export default ResultadosSubcategorias;
