import React, { useState, useEffect, useContext } from "react";
import axios from "../fetch/axios";
import { LanguageCurrencyContext } from "../context/LanguageCurrencyContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Component = ({ mini, forceMobileSettings }) => {
  const [igImages, setIgImages] = useState([]);
  const [loadingIgImages, setLoadingIgImages] = useState(true);
  const { lang } = useContext(LanguageCurrencyContext);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`/posts/home?lang=${lang}`);
        setIgImages(response.data);
      } catch (error) {
        console.error("Error fetching Instagram posts", error);
      } finally {
        setLoadingIgImages(false);
      }
    };
    fetchPosts();
  }, [lang]);

  const mobileSettings = {
    focusOnSelect: true,
    slidesPerRow: 1,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    centerPadding: "0",
    swipe: true,
    speed: 300,
  };

  const defaultSettings = {
    autoplay: true,
    speed: 1000,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 480,
        settings: mobileSettings,
      },
    ],
    afterChange: (current) => {
      let slides = document.querySelectorAll(".slick-slide");
      slides.forEach((slide) => {
        slide.classList.remove("slick-sprev", "slick-snext");
      });
  
      let activeSlide = document.querySelector(".slick-active");
      if (activeSlide) {
        let prev = activeSlide.previousElementSibling;
        let next = activeSlide.nextElementSibling;
        if (prev) prev.classList.add("slick-sprev");
        if (next) next.classList.add("slick-snext");
      }
    }
  };

  const settings = forceMobileSettings ? { ...defaultSettings, ...mobileSettings } : defaultSettings;

  const InstagramItem = ({ image, text, link }) => (
    <div className="InstagramItem">
      <a href={link}>
        <div style={{ backgroundImage: `url(${image})` }} className="InstagramImage"></div>
        <p className="TextLeft">
          <i className="icon-Instagram"></i>
          <span>{text}</span>
        </p>
      </a>
    </div>
  );

  const LoadingCard = () => (
    <div className="LoadingWrapper">
      {Array.from({ length: 4 }).map((_, index) => (
        <div key={index} className="card-loader card-loader--tabs"></div>
      ))}
    </div>
  );

  return (
    <div className={`InstagramBox${mini ? " IGBoxMini" : ""}`}>
      {loadingIgImages ? (
        <div className="MainContentItems MCtemsButtons">
          <LoadingCard />
        </div>
      ) : (
        <Slider {...settings}>
          {igImages.map((post, index) => (
            <InstagramItem key={index} image={post.image} text={post.description} link={post.link} />
          ))}
        </Slider>
      )}
    </div>
  );
};

export default Component;