import React, { useState, useRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from '../../fetch/axios';
import { useSnackbar } from "notistack";
import { CartContext } from "../../context/CardContext";
import { useTranslation } from "../../hooks/useTranslation";

const Login = () => {

  const [emailValue, setEmail] = useState("");
  const passwordBoxRef = useRef(null);
  const [passwordValue, setPassword] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { loadCartFromLocalStorage } = useContext(CartContext);
  const { translate } = useTranslation();

  const TogglePassword = (event) => {
    event = event.target;
    event.classList.toggle("icon-EyeClosed");
    event.classList.toggle("icon-EyeOpen");
    event = event.previousElementSibling;
    if (event.toggleAttribute("type")) {
      event.type = "password";
    }
  };

  const CheckPassword = (event) => {
    setPassword(event.target.value);
    if (passwordValue) {
      passwordBoxRef.current.classList.remove("InvalidInput");
    }
  };
  

  const Iniciar = async e => {
    e.preventDefault();
    passwordBoxRef.current.classList[!passwordValue ? "add" : "remove"](
      "InvalidInput"
    );
    if(emailValue && passwordValue){
      try {
        const res = await axios.post(
          "/users/login",
          {
            email: emailValue,
            password: passwordValue
          }
        );
        if (res.data.errors) {;
          enqueueSnackbar(res.data.message, { variant: "error" });
        } else {
          console.log(res.data)
          let user = {
            affiliation: res.data.affiliation,
            email: res.data.email,
            name:res.data.name,
            phone:res.data.phone,
            code_phone:res.data.code_phone
          }
          localStorage.setItem('user_arteonu', JSON.stringify(user));
          localStorage.setItem("token_arteonu", res.data.api_token);
          loadCartFromLocalStorage()

          enqueueSnackbar(translate('login.rollback'), { variant: "success" });
          navigate("/mi-perfil");
        }
      } catch (error) {
        if (error.response && error.response.status == 400) {
          enqueueSnackbar(translate('login.error2'), { variant: "error" });
        } else {
          enqueueSnackbar(translate('login.error3'), { variant: "error" });
        }
      }
    }else{
      enqueueSnackbar(translate('login.error1'), { variant: "error" });
    }
  };
  

  return (
    <>
      {/* if not <> -> use className='DataBox' */}
      <label className="FormData">
        <em className="RequiredData">{translate('login.email')} </em>
        <div className="FormInput">
          <input
            placeholder={translate('login.email')}
            value={emailValue}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </label>
      {/**
       *
       * ! InvalidInput className in label-> error input style
       *
       **/}
      <label className="FormData" ref={passwordBoxRef}>
        <em className="RequiredData">{translate('login.password')}</em>
        <div className="FormInput">
          <input
            placeholder={translate('login.password')}
            type="password"
            value={passwordValue}
            onChange={CheckPassword}
          />
          <i className="InputIcon icon-EyeClosed" onClick={TogglePassword}></i>
        </div>
        <em className="InfoData">{translate('login.password_no')}</em>
      </label>
      <div className="ButtonLightMedium" onClick={Iniciar}>
        {translate('login.titleM')}
        <i className="icon-ArrowRight"></i>
      </div>
      <div className="TextColorSec">
        <em>{translate('login.forgout')} </em>
        <Link to="/login-recuperar">
          <b>{translate('recovery.title')} </b>
        </Link>
      </div>
    </>
  );
};

export default Login;
