import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../fetch/axios";
import { TokenContext } from "../../context/TokenContext";
import { useTranslation } from "../../hooks/useTranslation";

const AgregarEditarDireccionEnvio = () => {

  const { idDireccion } = useParams();
  const [id, setId] = useState(idDireccion ? idDireccion : null);
  const [street1, setStreet1] = useState(null);
  const [street2, setStreet2] = useState(null);
  const [cp, setCp] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [detail, setDetail] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { getToken } = useContext(TokenContext);
  const {translate} = useTranslation();
  const countries = JSON.parse(localStorage.getItem('countries_arteonu'));

  const navigate = useNavigate();

  const guardar = async (type=1) => {

    if (!street1) {
      enqueueSnackbar("El campo de dirección es obligatorio", { variant: "error" });
      return;
    }
    if (!cp || isNaN(cp)) {
      enqueueSnackbar("El código postal debe ser un número entero", { variant: "error" });
      return;
    }
    if (!city) {
      enqueueSnackbar("El campo de ciudad es obligatorio", { variant: "error" });
      return;
    }
    try {
        await axios[type == 1 ? 'post' : 'put'](
          type == 1 ? '/addresses/create' : '/addresses/update',
          {
            id: id,
            country_id: country,
            line: street1,
            line2: street2,
            postal_code: cp,
            city: city,
            details: detail
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
            }
          }
        );
        enqueueSnackbar("Dirección guardada con éxito", { variant: "success" });
        navigate('/mi-perfil');
      } catch (error) {
        console.error('Error al guardar la dirección:', error);
        enqueueSnackbar("Ocurrió un error al guardar la dirección", { variant: "error" });
      }
      

  };

  const eliminar = async () => {

    try {
      await axios.delete('/addresses/remove?id='+idDireccion,{
        headers: {'Authorization': 'Bearer ' + getToken()}
      });
      
        enqueueSnackbar("Direccion eliminada con éxito", { variant: "error" });
        navigate('/mi-perfil')
      
    } catch (error) {
      enqueueSnackbar("No se puede eliminar la direccioón por los momentos", { variant: "error" });
      navigate('/mi-perfil')
    }

  };

  useEffect(()=>{

    if(idDireccion){

      const fetchDireccion = async () => {

        const user = JSON.parse(localStorage.getItem('user_arteonu'));
        try {
          const res = await axios.get('/addresses/detail?id='+idDireccion,{
            headers: {'Authorization': 'Bearer ' + getToken()}
          });
          const dir = res.data;
          if(dir.email==user.email){
            setId(dir.id)
            setStreet1(dir.line)
            setStreet2(dir.line2)
            setCp(dir.postal_code)
            setDetail(dir.details)
            setCity(dir.city)
            setCountry(dir.country_id)
          }else{
            enqueueSnackbar("Direccion no disponible", { variant: "error" });
            navigate('/mi-perfil')
          }
        } catch (error) {
          enqueueSnackbar("Direccion no disponible", { variant: "error" });
          navigate('/mi-perfil')
        }
      };

      fetchDireccion();
    }

  },[])

  return (
    <>
      <div className="PerfilContent pt-tophead">

        <div className={`CategoryTitle PerfilSection`}>
          <ul className="Breadcrumb">
            <li onClick={() => { navigate('/home') }} style={{ cursor: "pointer" }}>Home</li>
            <li onClick={() => { navigate('/mi-perfil') }} style={{ cursor: "pointer" }}>{translate('view.myprofile.my_profile')}</li>
            <li>{idDireccion ? translate('view.address.edit') : translate('view.address.add')} {translate('view.address.address')}</li>
          </ul>
        </div>

        <div className="grid-title" style={{ display: 'block' }}>

          <h2>{idDireccion ? translate('view.address.edit_shipping') : translate('view.address.add_shipping')}</h2>

        </div>

        <hr />

        <section className="DireccionEnvio">

          <div className="form-box">

            <label className="FormData">

              <em className="RequiredData">{translate('view.address.street_numer_apt_1')}</em>

              <div className="FormInputLight">
                <input placeholder={translate('view.address.street_numer_apt_1')} onChange={(e) => { setStreet1(e.target.value) }} value={street1} />
              </div>

            </label>

            <label className="FormData">

              <em className="RequiredData">{translate('view.address.street_numer_apt_2')}</em>

              <div className="FormInputLight">
                <input placeholder={translate('view.address.street_numer_apt_2')} onChange={(e) => { setStreet2(e.target.value) }} value={street2} />
              </div>

            </label>

            <div className="form-row">

              <label className="FormData">
                <em className="RequiredData">{translate('view.address.postal_code')}</em>
                <div className="FormInputLight">
                  <input placeholder={translate('view.address.postal_code')} onChange={(e) => { setCp(e.target.value) }}  value={cp}/>
                </div>
              </label>


              <label className="FormData">
                <em className="RequiredData">{translate('view.address.city')}</em>
                <div className="FormInputLight">
                  <input placeholder="Ciudad" onChange={(e) => { setCity(e.target.value) }} value={city} />
                </div>
              </label>

            </div>

            <label className="FormData">
              <em className="RequiredData">{translate('login.country')}</em>
              <div className="FormInputLight">
                <select onChange={(e) => {
                    const selectedCountryId = parseInt(e.target.value, 10); // Parse to integer
                    const selectedCountry = countries.find(country => country.id == selectedCountryId);
                    if (selectedCountry) {
                      setCountry(selectedCountry.id); // Assuming you still want to set the country ID
                    }
                  }}>
                    {countries &&
                      countries.map((pais) => (
                        <option key={pais.id} value={pais.id}
                        selected={(!country && pais.id === 142)|| country==pais.id } 
                        >
                          {pais.name}
                        </option>
                      ))}
                  </select>
                <i className="InputIcon icon-Select"></i>
              </div>
            </label>


            <label className="FormData">
              <em className="RequiredData">{translate('view.address.detail')}</em>
              <div className="FormInputLight">
                <textarea placeholder={translate('view.address.adddetail')} onChange={(e) => { setDetail(e.target.value) }} value={detail}></textarea>
              </div>
            </label>

            <button className="ButtonLightMedium" onClick={() => { guardar(idDireccion ? 2 : 1) }}>
              {idDireccion ? translate('view.address.update') : translate('view.address.store')} 
            </button>

            {idDireccion && <button className="DeleteAddressButton" style={{ cursor:'pointer' }} onClick={()=>{eliminar()}}>{translate('view.address.delete')}</button>}


          </div>

        </section>

      </div>
    </>
  );

};

export default AgregarEditarDireccionEnvio;
