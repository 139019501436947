import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PodriaInteresarte from "./PodriaInteresarte";
import { CartContext } from "../../context/CardContext";
import PreviewItemCarrito from "./PreviewItemCarrito";
import axios from "../../fetch/axios";
import { TokenContext } from "../../context/TokenContext";
import { useSnackbar } from "notistack";
import { LanguageCurrencyContext } from "../../context/LanguageCurrencyContext";
import { useTranslation } from "../../hooks/useTranslation";

const Carrito = () => {

  const navigate = useNavigate();
  const { getCartItems,totalPay,subtotalPay,totalQuantity,setSubtotalPay,getCardId,setTotalPay,setDescuento} = useContext(CartContext);
  const { enqueueSnackbar } = useSnackbar();
  const { getToken } = useContext(TokenContext);
  const [code,setCode] = useState(null);
  const { lang, currency } = useContext(LanguageCurrencyContext);
  const [productos,setProductos] =useState([]);
  const [discount,setDiscount]=useState(null);
  const [shipping,setShipping]=useState(null);
  const [subtotal,setSubtotal]=useState(null);
  const {translate} = useTranslation();

  const realizarCompra = ()=>{
    if(getCartItems().length>0){
      navigate("/checkout-envio")
    }else{
      navigate("/home")
    }
  }

  const aplicarCupon= async ()=>{

    if(code){

      if (getCartItems().length>0) {
        
        try{
          
          const res = await axios.put(
            "/discount-code/apply",
            {
              "cart_id": getCardId(),
              "code": code       
            },
            {
              headers: { 'Authorization': 'Bearer ' + getToken() }
            }
          );
      
          if (res.data.errors) {
            enqueueSnackbar('Introduzca un codigo válido', { variant: "error" });
          } else {
            setTotalPay(res.data.total)
            setDescuento(res.data.discount)
            setDiscount(res.data.discount)
            enqueueSnackbar('Codigo introducido correctamente', { variant: "success" });
          }
        } catch (error) {
          enqueueSnackbar(error.response.data, { variant: "error" });
        }
      } else {
        enqueueSnackbar('Debe de tener items en el carrito para usar este codigo', { variant: "error" });

      }

    }else{
      enqueueSnackbar('Introduzca un codigo válido', { variant: "error" });
    }

  }

	useEffect(() => {

		const fetchCarrito = async () => {

      if(localStorage.getItem('cartId')!==null){

        try {

          const res = await axios.get('/cart/order?id='+ localStorage.getItem('cartId'),
            {
              headers: { 'Authorization': 'Bearer ' + getToken() }
            }
          );
          if (res.data) {
            setProductos(res.data.cart_products)
            setDiscount(res.data.discount)
            setShipping(res.data.shipping)
            setSubtotalPay(res.data.subtotal)
          }
  
  
        } catch (error) {
        }

      }

		};

		fetchCarrito()
		
	}, [lang,currency])

  return (
    <>

      <section className="Carrito">
        <div className="CarritoList">
          <h4>{translate('shopping.title')}</h4>

          {productos.length>0 && getCartItems().map((producto, index) => (
            <PreviewItemCarrito key={index} producto={producto} redirect={false}  />
          ))}

        </div>
        <div className="CarritoResume">
          <b>{translate('shopping.discount_code')} </b>
          <p className="FontMedium">
            {translate('shopping.if_code')}
          </p>
          <div className="Row">
            <div className="FormInput">
              <input placeholder={translate('shopping.discount_code')} onChange={(e)=>{setCode(e.target.value)}}/>
            </div>
            <div className="ButtonDarkMedium" onClick={()=>{aplicarCupon()}}>{translate('shopping.apply')}</div>
          </div>
          <div className="Row">
            <p>{translate('shopping.total_products')}</p>
            <h6>{totalQuantity} </h6>
          </div>
          {subtotalPay ?
          <div className="Row">
            <p>{translate('shopping.subtotal')}</p>
            <h6>{subtotalPay} </h6>
          </div>
         :'' }
          {discount && 
          <div className="Row">
            <p>{translate('shopping.discount')}</p>
            <h6>{discount} </h6>
          </div>
          }
          {shipping && 
          <div className="Row">
            <p>{translate('shopping.shipping')}</p>
            <h6>{shipping} </h6>
          </div>
          }
          <div className="Row">
            <h5>Total:</h5>
            <h5>${totalPay}</h5>
          </div>
          <div
            className="ButtonDark"
            onClick={() => realizarCompra()}
          >
            {translate('shopping.purchased')}
            <i className="icon-Check"></i>
          </div>
        </div>
      </section>
			<PodriaInteresarte />
    </>
  );
};
export default Carrito;
