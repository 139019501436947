import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../fetch/axios";
import { TokenContext } from "../../context/TokenContext";
import { useTranslation } from "../../hooks/useTranslation";

const EditarMisDatos = () => {

    const navigate = useNavigate();
    const {translate} = useTranslation();
    const { getToken } = useContext(TokenContext);
    
    const storedUser = localStorage.getItem('user_arteonu');
    const [user, setUser] = useState(storedUser ? JSON.parse(storedUser) : null);
    const [name, setName] = useState(user?.name || '');
    const [email, setEmail] = useState(user?.email || '');
    const [phone, setPhone] = useState(user?.phone || '');
    const [country, setCountry] = useState(user?.country_id || 142);
    const { enqueueSnackbar } = useSnackbar();
    const countries = JSON.parse(localStorage.getItem('countries_arteonu'));

    const guardar = async()=>{
      if(!name  || !email  || !phone ){
        enqueueSnackbar("Faltan campos por llenar", { variant: "error" });
        return;
      }
  
      try {
        const res = await axios.put(
          "/users/data",
          {
            "name": name,
            "country_id": country,
            "phone": phone,
            "email": email,
          },
          {
            headers: { 'Authorization': 'Bearer ' + getToken() }
          }
        );
        if (res.data.errors) {
          enqueueSnackbar(res.data.message, { variant: "error" });
        } else {

          let us = {
            'name':res.data.name,
            'email':res.data.email,
            'phone':res.data.phone,
            'country_id':res.data.country_id,
            'affiliation':res.data.affiliation,
            'code_phone':res.data.code_phone
          }
          setUser(us)
          localStorage.setItem('user_arteonu',JSON.stringify(us))
          enqueueSnackbar("Datos actualizados correctamente", { variant: "success" });
        }
      } catch (error) {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      }
    }

    useEffect(() => {
      if (user) {
        setName(user.name);
        setEmail(user.email);
        setPhone(user.phone);
      }
    }, [user]);

  return (
    <>
      <div className="PerfilContent pt-tophead">

        <div className={`CategoryTitle PerfilSection`}>
          <ul className="Breadcrumb">
            <li onClick={() => { navigate('/home') }} style={{ cursor: "pointer" }}>Home</li>
            <li onClick={() => { navigate('/mi-perfil') }} style={{ cursor: "pointer" }}>{translate('view.myprofile.my_profile')}</li>
            <li>Editar Perfil</li>
          </ul>
        </div>

        <div className="grid-title" style={{ display: 'block' }}>

          <h2>{translate('view.myprofile.edit_my_profile')}</h2>

        </div>

        <hr />

        <section className="EditarMisDatos">

          <div className="form-box">

            <label className="FormData">

              <em className="RequiredData">{translate('view.myprofile.name')}</em>

              <div className="FormInputLight">
                <input placeholder={translate('login.nameplacesholder')} value={name} disabled/>
              </div>

            </label>

            <div className="form-row">

              <label className="FormData lada">
                <em className="RequiredData">{translate('login.country')} </em>
                <div className="FormInputLight">
                <select onChange={(e) => {
                    const selectedCountryId = parseInt(e.target.value, 10); // Parse to integer
                    const selectedCountry = countries.find(country => country.id == selectedCountryId);
                    if (selectedCountry) {
                      setCountry(selectedCountry.id); // Assuming you still want to set the country ID
                      setPhone(selectedCountry.phone_code);
                    }
                  }}>
                    {countries &&
                      countries.map((pais) => (
                        <option key={pais.id} value={pais.id}
                        selected={(!country && pais.id === 142)|| country==pais.id } 
                        >
                          {pais.name}
                        </option>
                      ))}
                  </select>
                  <i className="InputIcon icon-Select"></i>
                </div>
              </label>


              <label className="FormData phone-number">
                <em className="RequiredData">{translate('login.phone')} </em>
                <div className="FormInputLight">
                  <input placeholder="5500000000" onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
                </div>
              </label>


            </div>

            <label className="FormData">
              <em className="RequiredData">{translate('view.myprofile.email')} </em>
              <div className="FormInputLight">
                <input placeholder={translate('view.myprofile.email')} onChange={(e)=>{setEmail(e.target.value)}} value={email}/>
              </div>
            </label>

            <button className="ButtonLightMedium" onClick={()=>guardar()}>
              {translate('view.myprofile.store')}
            </button>

          </div>

        </section>

      </div>
    </>
  );
};

export default EditarMisDatos;
