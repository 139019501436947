import React from "react";
import { Link } from "react-router-dom";
import axios from "../../fetch/axios"
import { useSnackbar } from "notistack";
import { useTranslation } from "../../hooks/useTranslation";

const Component = () => {

  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useTranslation();

  const email = localStorage.getItem('email_crear');
  
  const reenviar = async () => {

    const token = localStorage.getItem('token_volver_crear');

    try{

      const res = await axios.put(
        "/users/resend-confirmation",
        {
          "token": token,
        }
      );
  
      if (res.data.errors) {
        enqueueSnackbar(res.data.message, { variant: "error" });
      } else {
        localStorage.setItem('token_volver_crear', res.data.token)
        enqueueSnackbar("Mensaje reenviado con éxito", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: "error" });
    }

  }

  return (
    <>
      <div className="icon-Mail IconLarge"></div>
      <div className="TextLeft">
        <p>
          {translate('confirmation.send1')} {email}, {translate('confirmation.send2')}
        </p>
        <p>
          {translate('confirmation.send3')}
        </p>
      </div>
      <div>
        <p>{translate('confirmation.send4')} </p>
        <b onClick={reenviar} style={{ cursor: 'pointer' }}
        >{translate('confirmation.send5')}</b>
      </div>
      <div className="TextColorSec">
        <em>{translate('confirmation.send6')} </em>
        <Link to="/login-recuperar">
          <b>{translate('confirmation.send7')}</b>
        </Link>
      </div>
    </>
  );
};

export default Component;
