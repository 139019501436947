import React from "react";
import {  useNavigate } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";

const CompraPendiente = () => {

  const navigate = useNavigate();

  const user =JSON.parse(localStorage.getItem('user_arteonu'))
  const {translate} = useTranslation();


  return (
    <section className="CheckoutBox CompraExitosa">
      <article>
        <div className="StepCheckout">
          <div className="Selected"></div>
          <div className="Selected"></div>
          <div className="Selected"></div>
          <div className="Selected"></div>
        </div>
        <div className="icon-Check"></div>
        <h5>{translate('checkout.success_pending')}</h5>
        <b>{translate('checkout.your_pay_pending')}</b>
        <div className="FontSmall">
          {translate('checkout.send1_pending')} {user.email}
          <br /> {translate('checkout.send2_pending')}
        </div>
        <div className="Row">
          <div
            className="ButtonLightMedium"
            onClick={() => navigate("/mis-pedidos")}
          >
            {translate('checkout.check')}
          </div>
          <div className="ButtonDarkMedium" onClick={() => navigate("/home")}>
            {translate('checkout.continue_shopping')}
            <i className="icon-Check"></i>
          </div>
        </div>
      </article>
    </section>
  );
};

export default CompraPendiente;
