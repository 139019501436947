import React, { useContext, useEffect, useRef, useState } from "react";
import axios from '../../fetch/axios';
import { LanguageCurrencyContext } from "../../context/LanguageCurrencyContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import ColeccionItem from "./ColeccionItem";
import { useTranslation } from "../../hooks/useTranslation";

const Resultados = () => {
  const [products, setProducts] = useState([]);
  const [pages, setPages] = useState(1);
  const { lang, currency } = useContext(LanguageCurrencyContext);
  const [pageCurrent, setPageCurrent] = useState(1);
  const { translate } = useTranslation();
  const coleccionPosition = useRef(null);
  const navigate = useNavigate();

  const { search } = useParams();

  const fetchProductos = async () => {

    let url = `/products/search?search=` + search + `&lang=` + lang + `&currency=` + currency + `&page=` + pageCurrent;

    try {
      const response = await axios.get(url);
      setProducts(response.data.products);
     
      setPages(response.data.pages);

    } catch (error) {
      console.error('Error al obtener los productos:', error);
      throw error;
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={pageCurrent === i ? 'Selected' : ''}
          style={pageCurrent === i ? {} : { "cursor": 'pointer' }}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </li>
      );
    }

    return (
      <section className="ColeccionPagination">
        <div style={{ "cursor": 'pointer' }}
          className="icon-CaretLeft"
          onClick={() => pageCurrent > 1 && handlePageChange(pageCurrent - 1)}
        ></div>
        <ul>{pageNumbers}</ul>
        <div style={{ "cursor": 'pointer' }}
          className="icon-CaretRight"
          onClick={() => pageCurrent < pages && handlePageChange(pageCurrent + 1)}
        ></div>
      </section>
    );
  };

  const handlePageChange = (newPage) => {
    setPageCurrent(newPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchProductos(),
      ]);
    };

    fetchData();
  }, [lang, currency, pageCurrent])

  function SetPositionColeccion(event) {
    const pos = ["Vertical", "Horizontal"];
    pos[2] = event.target.dataset.n.split(",");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][0]]}`)
      .classList.add("Selected");
    event.target.parentNode
      .querySelector(`.icon-${pos[pos[2][1]]}`)
      .classList.remove("Selected");
    coleccionPosition.current.classList.toggle("ColeccionHorizontal");
    coleccionPosition.current.classList.toggle("ColeccionVertical");
  }

  return (
    <>

      <div className={`CategoryTitle pt-tophead`}>
        <ul className="Breadcrumb">
          <li><Link to="/home">Home</Link></li>
          <li>{search}</li>
        </ul>
        {<h3>{search}</h3>}

      </div>


      <section className="ColeccionMenu" >

        <div>
          <span
            className="icon-Vertical"
            data-n="0,1"
            onClick={SetPositionColeccion}
          >
            {translate('collections.send3')}
          </span>
          <span
            className="icon-Horizontal Selected"
            data-n="1,0"
            onClick={SetPositionColeccion}
          >
            {translate('collections.send4')}
          </span>
        </div>

      </section>

      <section
        className="ColeccionItems ColeccionVertical"
        ref={coleccionPosition}
      >
        {products.map(product => (
          <ColeccionItem
            key={product.id}
            product={product}
            productUri={product.uri || product.id} // Pasamos el uri por separado
          />
        ))}
      </section>

      {pages > 1 && renderPagination()}

    </>
  );
};

export default Resultados;
