import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Person7, Person12 } from "../../components/Images";
import InstagramBox from "../../components/InstagramBox";
import { useTranslation } from "../../hooks/useTranslation";
import { LanguageCurrencyContext } from "../../context/LanguageCurrencyContext";
import axios from '../../fetch/axios';

const Component = () => {

  const navigate = useNavigate();
  const { lang } = useContext(LanguageCurrencyContext);
  const [terminos, setTerminos] = useState(null);
  const { translate } = useTranslation();

  useEffect(() => {
    const fetchPoliticas = async () => {
      try {
        const response = await axios.get('/legal-texts/detail/11' + `?lang=` + lang);
        setTerminos(response.data?.data);
      } catch (error) {
        console.error('Error fetching Instagram posts', error);
      }
    };
    fetchPoliticas();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [lang])

  return (
    <>
      <section className="SobreNosotros ImageButton pt-tophead">
        <article
          className="ImageButtonBG"
          style={{ backgroundImage: `url(${Person12})` }}
        >
          <div>
            <div className="ButtonContent ButtonDark">{terminos && terminos?.name}</div>
          </div>
        </article>
        <article className="MainBox">
          <div>
            <div className="Row">
              <div>
                <div className="ArtOnU BlackImage"></div>

                {terminos && (
                  <div dangerouslySetInnerHTML={{ __html: terminos?.content }} />
                )} 
              </div>
              <div>
                <div className="ButtonContent ButtonLightMedium ButtonDark" onClick={() => navigate("/home")}>
                  {translate('legal.category')}
                  <i className="icon-ArrowRight"></i>
                </div>
                <br /><br />
                
                <div className="AboutImage" style={{ backgroundImage: `url(${Person7})` }} >
                  <div className="WeCreateToBeYourself BlackImage"></div>
                </div>
                
                
              </div>
            </div>
            <div className="InstagramAbout Row">
              {<InstagramBox forceMobileSettings={true} mini />}
            </div>
          </div>
        </article>
        <article>
        <div className="Contactanos">
            <div>
              <b>{translate('legal.dudas')}</b>
            </div>
            <div>{translate('legal.description')}</div>
            <div
              className="ButtonContent ButtonDark"
              onClick={() => navigate("/contacto")}
            >
              {translate('legal.contact')}
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Component;
