import React, { createContext, useContext, useEffect, useState } from 'react';
import { TokenContext } from './TokenContext';
import axios from "../fetch/axios"

export const LanguageCurrencyContext = createContext({
  lang: localStorage.getItem('lang_arteonu')!==null ?localStorage.getItem('lang_arteonu') :'es' ,
  currency: localStorage.getItem('currency_arteonu')!==null ? localStorage.getItem('currency_arteonu'):'mxn',
  cambiarLang: () => {},
  cambiarCurrency: () => {},
});

export const LanguageCurrencyProvider = ({ children }) => {

  const [lang, setLang] = useState(localStorage.getItem('lang_arteonu')!==null ?localStorage.getItem('lang_arteonu') :'es');
  const [currency, setCurrency] = useState(localStorage.getItem('currency_arteonu')!==null ? localStorage.getItem('currency_arteonu'):'mxn');
  const {getToken} =useContext(TokenContext);

  const cambiarLang = (newLang) => {
    setLang(newLang);
    if(newLang=='es'){
      cambiarCurrency('mxn')
    }else{
      cambiarCurrency('usd')
    }
    localStorage.setItem('lang_arteonu', newLang);
  };

  const cambiarCurrency = async(newCurrency) => {
    setCurrency(newCurrency);
    localStorage.setItem('currency_arteonu', newCurrency);

    if(getToken() && localStorage.getItem('cartId')!==null && localStorage.getItem('cartId')!==''){

      await axios.put('/cart/currency', {
        cart_id: localStorage.getItem('cartId'),
        currency: newCurrency === 'usd' ? 'USD' : 'MXN'
      }, {
        headers: { 'Authorization': 'Bearer ' + getToken() }
      });

      window.location.reload();
    }

  };

  const countries = async()=>{
    if (!localStorage.getItem('countries_arteonu')) {
      try {
        const response = await axios.get(`/countries`);
        localStorage.setItem('countries_arteonu', JSON.stringify(response.data));
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    }
  }

  useEffect(() => {
    const storedLang = localStorage.getItem('lang_arteonu');
    const storedCurrency = localStorage.getItem('currency_arteonu');
    
    if (storedLang !== null && storedLang !== '') {
      setLang(storedLang);
    }
    
    if (storedCurrency !== null && storedCurrency !== '') {
      setCurrency(storedCurrency);
    }

    countries()
  }, []);


  return (
    <LanguageCurrencyContext.Provider value={{ 
      lang, 
      currency, 
      cambiarLang, 
      cambiarCurrency 
      }}>
      {children}
    </LanguageCurrencyContext.Provider>
  );
};
