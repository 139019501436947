import React, { useContext, useState } from "react";
import FigureProduct from "../../components/FigureProduct";
import axios from "../../fetch/axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { TokenContext } from "../../context/TokenContext";
import { useTranslation } from "../../hooks/useTranslation";

const ProductoResultado = ({ producto, ejecutar }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { getToken } = useContext(TokenContext);
  const { translate } = useTranslation();

  const [showSocials, setShowSocials] = useState(false); 
  const [showNotification, setShowNotification] = useState(false); 
  const url = process.env.REACT_APP_URL+"detalles-producto/"+producto.uri;

  const AddToHeart = async (event, id) => {
    try {
      if (!getToken()) {
        navigate('/login');
      }
      const res = await axios.put(
        "/products/favorite",
        {
          product_id: id,
          status: !producto.favorite
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + getToken()
          }
        }
      );
      producto.favorite = !producto.favorite;

      event.target.classList.toggle('icon-Hearty');
      event.target.classList.toggle('icon-HeartFill');
      if (ejecutar) ejecutar();
      if (res.data.errors) {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("No se pudo agregar a favoritos", { variant: "error" });
    }
  };

  const toggleSocials = () => {
    setShowSocials(!showSocials);
  };

  const handleCopyLink = (event) => {

    event.preventDefault();
    
    navigator.clipboard.writeText(url).then(() => {
      
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000); 
    });
  };

  return (
    <>
      <article>
        <div>
          {<FigureProduct imagedesktop={producto.desktop_image} imagemovile={producto.movile_image} uri={producto.uri} />}
          <i
            className={!producto.favorite ? "icon-Hearty" : "icon-HeartFill"}
            style={{ color: !producto.favorite ? "" : "red" }}
            onClick={(event) => { AddToHeart(event, producto.id); }}
          ></i>
          <i className="icon-Share" onClick={toggleSocials}></i>
          {showSocials && (
            <div className="socials-list">
              <a target="_blank" href={'https://www.facebook.com/sharer/sharer.php?u='+url} className="social-icon facebook"></a>
              {/* <a target="_blank" href={'https://twitter.com/intent/tweet?url='+url} className="social-icon twitter"></a> */}
              <a href="#" className="social-icon link" onClick={handleCopyLink}></a>
            </div>
          )}
          {/* Bandera de notificación */}
          {showNotification && (
            <div className="notification-banner">
              Enlace copiado
            </div>
          )}
        </div>
        <div>
          <div className="ItemDesc">
            <br />
            {((!producto.offer_price_range &&  !producto.price_range) && producto.price_offer) && <div className="textpromo">{translate('product.discount')}</div>}
            <ul>
              <li>{producto.category}</li>
              <li>{producto.subcategory}</li>
            </ul>
            <h6>{producto.name}</h6>
            {producto.offer_price_range ?  <span>${producto.offer_price_range}</span> : ''}
            {(!producto.offer_price_range &&  producto.price_range) ?  <span>${producto.price_range}</span> : ''}

           {((!producto.offer_price_range &&  !producto.price_range) && !producto.price_offer) && <span>${producto.price}</span>}
            {((!producto.offer_price_range &&  !producto.price_range) && producto.price_offer) &&<div className="grid-price">
              <span>${producto.price}</span>
              <div className="promo">{producto.price_offer} </div>
            </div>}
            <em>{producto.description}</em>
            <br />
            <br />
          </div>
        </div>
        
      </article>
    </>
  );
};

export default ProductoResultado;