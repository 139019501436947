import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../../fetch/axios";
import { useTranslation } from "../../hooks/useTranslation";


const Component = () => {

  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [phone, setPhone] = useState(52);
  const [country, setCountry] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useTranslation();

  const countries = JSON.parse(localStorage.getItem('countries_arteonu'));

  const TogglePassword = (event) => {
    event = event.target;
    event.classList.toggle("icon-EyeClosed");
    event.classList.toggle("icon-EyeOpen");
    event = event.previousElementSibling;
    if (event.toggleAttribute("type")) {
      event.type = "password";
    }
  };

  const registrarse = async () => {

    if (!name || !email || !password || !phone) {
      enqueueSnackbar("Faltan campos por llenar", { variant: "error" });
      return;
    }

    try {
      const res = await axios.post(
        "/users/sign-in",
        {
          "name": name,
          "country_id": country,
          "phone": phone,
          "email": email,
          "password": password
        }
      );
      if (res.data.errors) {
        enqueueSnackbar(res.data.message, { variant: "error" });
      } else {
        localStorage.setItem('token_volver_crear', res.data.token)
        localStorage.setItem('email_crear', email)
        navigate("/login-confirmar-registro")
        enqueueSnackbar("Mensaje reenviado con éxito", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: "error" });
    }

  }

  return (
    <>
      <label className="FormData">
        <em className="RequiredData">{translate('login.name')}</em>
        <div className="FormInput">
          <input placeholder={translate('login.nameplacesholder')} onChange={(e) => { setName(e.target.value) }} />
        </div>
      </label>
      <div className="Row">
        <label className="FormData">
          <em className="RequiredData">{translate('login.country')}</em>
          <div className="FormInput">
            <select onChange={(e) => {
              const selectedCountryId = parseInt(e.target.value, 10); // Parse to integer
              const selectedCountry = countries.find(country => country.id == selectedCountryId);
              if (selectedCountry) {
                setCountry(selectedCountry.id); // Assuming you still want to set the country ID
                setPhone(selectedCountry.phone_code);
              }
            }}>
              {countries &&
                countries.map((pais) => (
                  <option key={pais.id} value={pais.id}
                  selected={!country && pais.id == 142} >
                    {pais.name}
                  </option>
                ))}
            </select>
            <i className="InputIcon icon-Select"></i>
          </div>
        </label>
        <label className="FormData">
          <em className="RequiredData">{translate('login.phone')}</em>
          <div className="FormInput">
            <input placeholder="5500000000" onChange={(e) => { setPhone(e.target.value) }} value={phone}/>
          </div>
        </label>
      </div>

      <label className="FormData">
        <em className="RequiredData">{translate('login.email')}</em>
        <div className="FormInput">
          <input placeholder={translate('login.email')} onChange={(e) => { setEmail(e.target.value) }} />
        </div>
      </label>
      <label className="FormData">
        <em className="RequiredData">{translate('login.password')}</em>
        <div className="FormInput">
          <input placeholder={translate('login.password')} type="password" onChange={(e) => { setPassword(e.target.value) }} />
          <i className="InputIcon icon-EyeClosed" onClick={TogglePassword}></i>
        </div>
      </label>
      <div className="TextColorSec">
        {translate('login.term1')} <Link to="/404">"{translate('login.term2')}"</Link>, <Link to="/404">"{translate('login.term3')}"</Link>, <Link to="/404">"{translate('login.term4')}"</Link> y <Link to="/404">"{translate('login.term5')}"</Link>, {translate('login.term6')} <Link to="/404">"{translate('login.term7')}"</Link>.
      </div>
      <div
        className="ButtonLightMedium"
        onClick={() => registrarse()}
      >
        {translate('login.registerM')}
        <i className="icon-ArrowRight"></i>
      </div>
      <div>
        <p>{translate('login.already_account')}</p>
        <Link to="/login">
          <b>{translate('login.title')}</b>
        </Link>
      </div>
      <div className="TextColorSec">
        <em>{translate('login.forgout')} </em>
        <Link to="/login-recuperar">
          <b>{translate('recovery.title')} </b>
        </Link>
      </div>
    </>
  );
};

export default Component;
