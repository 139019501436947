import React from "react";
import axios from "../../fetch/axios"
import { useSnackbar } from "notistack";
import { useTranslation } from "../../hooks/useTranslation";

const Component = () => {
  
  const { translate } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const token_recuperar_clave = localStorage.getItem("token_recuperar_clave");
  const token_email = localStorage.getItem("token_email");

  const reenviar = async (type=1) => {
    try {
      const res = await axios.put(
        "/users/resend-password-recovery",
        {
          "token": token_recuperar_clave,
          "type": type
        }
      );
      if (res.data.errors) {
        enqueueSnackbar(res.data.message, { variant: "error" });
      } else {
        localStorage.setItem('token_recuperar_clave', res.data.token);
        enqueueSnackbar("Mensaje reenviado con éxito", { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: "error" });
    }
  }

  return (
    <>
      <div className="icon-Mail IconLarge"></div>
      <div className="TextLeft">
        <p>
          {translate('confirmation.send1')} {token_email}, {translate('confirmation.send8')}
        </p>
        <p>
          {translate('confirmation.send9')}
        </p>
      </div>
      <div>
        <p>{translate('confirmation.send10')} </p>
        <b onClick={()=>{reenviar(1)}}>{translate('confirmation.send11')}</b>
      </div>
      <div>
          <b onClick={()=>{reenviar(2)}}>{translate('confirmation.send12')}</b>
      </div>
    </>
  );
};

export default Component;
