import React, { useContext, useEffect, useState } from 'react';
import PodriaInteresarte from '../home/PodriaInteresarte';
import Breadcrumb from '../../components/Breadcrumb';
import AgregadoItem from '../home/AgregadoItem';
import axios from "../../fetch/axios";
import { TokenContext } from '../../context/TokenContext';
import { useNavigate } from 'react-router-dom';
import { LanguageCurrencyContext } from '../../context/LanguageCurrencyContext';
import { useTranslation } from '../../hooks/useTranslation';

const Component = () => {

	const [openPanelIndex, setOpenPanelIndex] = useState(null); // Estado para el panel abierto
	const { getToken } = useContext(TokenContext);
  	const navigate = useNavigate();
  	const { lang, currency } = useContext(LanguageCurrencyContext);
	const  {translate}    = useTranslation();
	// Función para alternar el panel
	const togglePanel = (index) => {
		setOpenPanelIndex(openPanelIndex === index ? null : index);
	};

	const [pedidos, setPedidos] = useState([])

	useEffect(() => {

		const fetchPedidos = async () => {

			try {

				const res = await axios.get('/cart/orders'+ `?lang=` + lang + `&currency=` + currency,
					{
						headers: { 'Authorization': 'Bearer ' + getToken() }
					}
				);
				if (res.data) {

					setPedidos(res.data);
				}


			} catch (error) {
			}
		};

		fetchPedidos()

	}, [lang,currency])


	return (
		<>
			<div className={`CategoryTitle PerfilSection pt-tophead`}>
				<ul className="Breadcrumb">
					<li onClick={() => { navigate('/home') }} style={{ cursor: "pointer" }}>Home</li>
					<li onClick={() => { navigate('/mi-perfil') }} style={{ cursor: "pointer" }}>{translate('view.myprofile.my_profile')}</li>
					<li>{translate('view.orders.title')}</li>
				</ul>
			</div>

			<section className="MisPedidos">
				{pedidos.length > 0 && pedidos.map((pedido, index) => (
					<div className="itemPedido" key={pedido.id}>
						<div className="tab">
							<div>
								<div>
									<div className="title">ID del pedido:</div>
									<span className="id-pedido"><strong>{pedido.id}</strong></span>
								</div>
								<div>
									<div className="title"><strong>Pedido realizado el:</strong></div>
									<span>{pedido.date}</span>
								</div>
							</div>
							<div>
								<div>
									<div className="title">Total:</div>
									<span className="total"><strong>{pedido.total}</strong></span>
								</div>
								<div>
									<button
										className="btn"
										onClick={() => togglePanel(index)}
									>
										{openPanelIndex === index ? 'Ocultar Detalles' : 'Detalles'}
									</button>
								</div>
							</div>
						</div>
						{/* Mostrar u ocultar el panel según el estado */}
						{openPanelIndex === index && (
							<div className="panel">
								<div className="detail">
									<div>
										<div className="title">Dirección de envío:</div>
										<span>{pedido.detail.shipment_address}</span>
									</div>
									<div>
										<div className="title">Estatus:</div>
										<div className="box-status">
											{pedido.detail.status} <a href={pedido.detail.waybill}>Rastrear el envío</a>
										</div>
										<br />
										<div className="box-status">
											<a onClick={()=>{navigate('/cambios-y-devoluciones')}}>Políticas de Cambio y Devolución</a>
										</div>
									</div>
									<div>
										<div className="title">Detalles del pedido:</div>
										<div className="text">
											Código de descuento: <strong>{pedido.detail.discount_code}</strong><br />
											Productos: <strong>${pedido.detail.products}</strong><br />
											Envío: <strong>${pedido.detail.shipping}</strong><br />
											Subtotal: <strong>${pedido.detail.subtotal}</strong><br />
											Promoción aplicada: <strong>-${pedido.detail.discount}</strong><br />
											{/* Total (IVA incluído): <strong>$469.00</strong> */}
										</div>
									</div>
									<div></div>
								</div>
								<div className="subtitle">Productos en el pedido:</div>
								{pedido.detail.cart_products.length>0 && 
									pedido.detail.cart_products.map((ped, index) => (
										<AgregadoItem ped={ped} key={index} />
									  ))
									
								}
							</div>
						)}
					</div>
				))}
			</section>

			<PodriaInteresarte />
		</>
	);
};

export default Component;