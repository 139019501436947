import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import InstagramBox from "../../components/InstagramBox";
import Figure from "../../components/Figure";
import axios from '../../fetch/axios';
import { LanguageCurrencyContext } from "../../context/LanguageCurrencyContext";
import { useTranslation } from "../../hooks/useTranslation";
import logoarteonuwhite from "../../assets/images/logo_arteonu_white.svg";

const Component = () => {
  const navigate = useNavigate();
  const [banner, setBanner] = useState(null);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loadingBanner, setLoadingBanner] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const { lang } = useContext(LanguageCurrencyContext);
  const {translate}  = useTranslation();
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await axios.get('/banners/home?lang='+lang);
        setBanner(response.data[0]);
      } catch (error) {
        console.error('Error fetching banner', error);
      } finally {
        setLoadingBanner(false);
      }
    };
    fetchBanner();
  }, [lang]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/categories/home?lang='+lang);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories', error);
      } finally {
        setLoadingCategories(false);
      }
    };
    fetchCategories();
  }, [lang]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/products/home');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products', error);
      } finally {
        setLoadingProducts(false);
      }
    };
    fetchProducts();
  }, []);

  const RowWithButton = (text, image, link,index) => {
    return (
      <div 
        key={index}
        style={{ backgroundImage: `url(${image})` }}>
        <div className="MainButton">
          <div className="ButtonLight2" onClick={() => navigate(link)}>
            {text}
            <i className="icon-ArrowRight"></i>
          </div>
        </div>
      </div>
    );
  };

  const LoadingCard = ({ cantidad = 4 }) => {
    const generateLoaderDiv = (index) => (
      <div key={index} className="col-md-3">
        <div className="wrapper">
          <div className="card-loader card-loader--tabs"></div>
        </div>
      </div>
    );
  
    return Array(cantidad).fill(0).map((_, index) => generateLoaderDiv(index));
  };
  

  return (
    
    <section className="pt-tophead">
      
      {/* Banner */}
      {/* <article className="MainImageBox" style={{ backgroundImage: `url(${banner ? banner.desktop_image : ''})` }}> */}
      <article className="MainImageBox" >
        <div className="heroImage">
          
          <picture>
           {(banner && !banner.mobile_is_video) && <source media="(max-width: 768px)" srcSet={banner ? banner.mobile_image : ''} />}
            {(banner && !banner.desktop_is_video) && <img src={banner ? banner.desktop_image : ''} alt="Banner" />}
          </picture>

          {/* CONTENEDOR PARA VIDEO */}
          {banner && (banner.desktop_is_video  || banner.mobile_is_video) && 
          <video className="heroVideo" autoPlay muted loop playsInline  >
            {(banner && banner.mobile_is_video) && <source src={banner ? banner.mobile_image : ''} type="video/mp4" media="(max-width: 768px)" />}
            {(banner && banner.desktop_is_video) && <source src={banner ? banner.desktop_image : ''} type="video/mp4" />}
            Tu navegador no soporta el video.
          </video>}

        </div>
        {loadingBanner ? (
          <div className="MainBox">
            <LoadingCard cantidad={1} />
          </div>
        ) : banner && banner.button && (
          <div className="MainButton">
            <div className="ButtonLight2">
            <a href={banner.link} className="link">
              {banner.button}
              <i className="icon-ArrowRight"></i>
            </a>

            </div>
          </div>
        )}
      </article>

      {/* Categorias */}
      <article className="MainBox">
        <div>
          <div className="MainContentItems MCtemsButtons">
            {loadingCategories ? LoadingCard(4) : categories.map((category,index) => (
              RowWithButton(category.name, category.desktop_image, `/category/${category.uri}`,index)
              
            ))}
          </div>
        </div>
      </article>

      {/* Contacto */}
      <article className="Sticky">
        <div
          className="ButtonContent ButtonDark"
          onClick={() => navigate("/contacto")}
        >
          Contact Us
        </div>
      </article>

      {/* Publicaciones Instagram */}
      <article className="MainBox">
        <div>
          <div className="WeCreateToBeYourself"></div>
          <InstagramBox />
        </div>
      </article>

      {/* Imagenes Figure */}
      <article id="NewArrivals" className="MainBox">
        <div>
          <div className="NewArrivals"></div>
          <div className="MainContentItems">
          {loadingProducts 
            ? <LoadingCard loading={true} />
            : products
                .filter(Boolean)
                .map((product, index) => (
                  <Figure key={index} image={product.desktop_image} uri={product.uri} />
                ))
          }

          </div>
        </div>
      </article>
      
      <article className="AboutBox">
        <div className="AboutUsBox">
          <div className="AboutUs"></div>
          <p>
           {translate('home.about')}
          </p>
          <div
            className="ButtonContent ButtonLightMini"
            onClick={() => navigate("/about")}
          >
            {translate('home.about_button')}
            <i className="icon-ArrowRight"></i>
          </div>
        </div>
        <div className="ArtFansBox">
          <div className="ArtFans"></div>
          <div className="StrokeDash"></div>
          <div>
            <ul>
            <li>{translate('home.li1')}</li>
            <li>{translate('home.li2')}</li>
            <li>{translate('home.li3')}</li>
            <li>{translate('home.li4')}</li>
              
            </ul>
          </div>
          <div
            className="ButtonContent ButtonLightMini"
            onClick={() => navigate("/login")}
          >
            {translate('home.register')}
            <i className="icon-ArrowRight"></i>
          </div>
        </div>
      </article>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-btn" onClick={() => setShowPopup(false)}>×</button>
            <img src={logoarteonuwhite} alt="Logo" className="popup-logo"/>
            <h2>Si quieres visitar nuestro sitio web, debes tener 18 años o más.</h2>
            <label className="custom-checkbox">
              <input type="checkbox" />
              <span className="checkmark"></span>
              Acepto términos de servicio
            </label>
            <button className="ButtonDark" onClick={() => setShowPopup(false)}>SOY MAYOR DE EDAD</button>
            <button className="ButtonLightMedium" onClick={() => navigate("/home")}>NO SOY MAYOR DE EDAD</button>
            <div className="social">¡Síguenos! <div className="grid"> <a href="https://www.facebook.com/arteonu/" target="_blank" className="facebook"></a> </div></div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Component;
