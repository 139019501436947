import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/icons/cards.svg";
import logopaypal from "../../assets/icons/paypal_logo.svg";
import zelle from "../../assets/icons/zelle_pay.svg";
import venmo from "../../assets/icons/venmo_pay.svg";
import { CartContext } from "../../context/CardContext";
import axios from "../../fetch/axios";
import { TokenContext } from "../../context/TokenContext";
import { useSnackbar } from "notistack";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import "../../styles/CheckStripe.scss"
import PayPaypal from "./PayPaypal";
import { useTranslation } from "../../hooks/useTranslation";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { Fancybox } from "@fancyapps/ui";

const Component = () => {

  const navigate = useNavigate();
  const [selectedPayment, setSelectedPayment] = useState("tarjeta"); // Estado para controlar la selección
  const { getCardId,totalPay,resetCart } = useContext(CartContext);
  const { getToken } = useContext(TokenContext);
  
  const { enqueueSnackbar } = useSnackbar();
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setPublicKey] = useState("");
  const {translate} = useTranslation();

  const appearance = {
    theme: 'stripe',
  };
  const loader = 'auto';

  useEffect(()=>{

    Fancybox.bind("[data-fancybox]", {
        // Opciones de Fancybox si
    });

    const fetchStripeKey = async () => {

      try {

        if(getCardId()){
          const res = await axios.get('/cart/stripe/payment?cart_id='+getCardId(),{
            headers: {'Authorization': 'Bearer ' + getToken()}
          });
          if(res.data){

            setClientSecret(res.data.client_secret)
            setPublicKey(loadStripe(res.data.public_key))
            
          }else{
            enqueueSnackbar("Checkout no disponible, intentelo de nuevo", { variant: "error" });
          }
          
        }

      } catch (error) {
        enqueueSnackbar("Stripe no disponible", { variant: "error" });
        // navigate('/carrito')
      }
    };

    
    fetchStripeKey()

  },[]);

  const enviaraPendiente= async() =>{

    
    try {

      if(getCardId()){
       
        const res = await axios.post('/cart/zelleyvenmo-payment',
          {
            "cart_id": getCardId(),
            "payment_method": selectedPayment
          },
          {
          headers: {'Authorization': 'Bearer ' + getToken()}
        });

        if(res.data){

          if(res.data.message=='Payment pending confirmation'){

            localStorage.setItem('cartId','')
            resetCart();
            navigate('/checkout-pendiente')

          }
          
        }else{
          enqueueSnackbar("Checkout no disponible, intentelo de nuevo", { variant: "error" });
        }
        
      }

    } catch (error) {
     
    }

  }

  return (
    <section className="CheckoutBox">
      <article>
        <div className="StepCheckout">
          <div className="Selected" onClick={()=>navigate('/checkout-envio')} style={{ cursor:"pointer" }}></div>
          <div className="Selected" onClick={()=>navigate('/checkout-resumen')} style={{ cursor:"pointer" }}></div>
          <div className="Selected"></div>
          <div></div>
        </div>
        <div className="icon-Wallet"></div>
        <div className="line">
          <h5>{translate('checkout.information')}</h5>
          <div className="box-price">
            <span>Total:</span>
            <div className="price">${totalPay}</div>
            <a href="#" onClick={(e) => { e.preventDefault(); navigate('/checkout-resumen'); }}
              >
              {translate('summary.see')}
              </a>
          </div>
        </div>

        {/* Opción Tarjeta */}
        <div className="TarjetasGuardadas">
          <div>{translate('checkout.credicard')}</div>
          <label className="ItemTarjeta">
            <input
              type="radio"
              name="payment"
              checked={selectedPayment === "tarjeta"}
              onChange={() => setSelectedPayment("tarjeta")}
            />
            <div className="Selected">
              <div>
                <img src={logo} alt="logo tarjeta" />
              </div>
            </div>
          </label>
        </div>
        {selectedPayment === "tarjeta" && (
          <div className="FormTarjeta">
            {clientSecret && (
              <Elements options={{clientSecret, appearance, loader}} stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
            )}
          </div>
        )}

        {/* Opción PayPal */}
        <div className="TarjetasGuardadas">
          <div>PayPal</div>
          <div className="FontSmall">
            {translate('checkout.paypal')}
          </div>
          <label className="ItemTarjeta">
            <input
              type="radio"
              name="payment"
              checked={selectedPayment === "paypal"}
              onChange={() => setSelectedPayment("paypal")}
            />
            <div className="Selected">
              <div>
                <img src={logopaypal} alt="logo paypal" />
              </div>
            </div>
          </label>
        </div>
        {selectedPayment === "paypal" && (
          <PayPaypal />
          
        )}

        {/* Opción Zelle */}
        <div className="TarjetasGuardadas">
          <div>Zelle</div>
          <label className="ItemTarjeta">
            <input type="radio" name="payment" checked={selectedPayment === "zelle"} onChange={() => setSelectedPayment("zelle")} />
            <div className="Selected" >
              <img src={zelle} alt="logo zelle" />
            </div>
          </label>
        </div>
        {selectedPayment === "zelle" && (
          <div className="FormButtonZelle" onClick={()=>{enviaraPendiente()}}>
            <a href="#modal-aviso-pay" 
            // data-fancybox data-src="#modal-aviso-pay" 
            data-type="inline" className="ButtonDarkMedium" >{translate('checkout.venmozelle')} </a>
          </div>
        )}

        {/* Opción Venmo */}
        <div className="TarjetasGuardadas">
          <div>Venmo</div>
          <label className="ItemTarjeta">
            <input type="radio" name="payment" checked={selectedPayment === "venmo"} onChange={() => setSelectedPayment("venmo")} />
            <div className="Selected">
              <img src={venmo} alt="logo venmo" />
            </div>
          </label>
        </div>
        {selectedPayment === "venmo" && (
          <div className="FormButtonVenmo" onClick={()=>{enviaraPendiente()}}>
            <a href="#modal-aviso-pay" 
            // data-fancybox data-src="#modal-aviso-pay" 
            data-type="inline" className="ButtonDarkMedium" > {translate('checkout.venmozelle')} </a>
          </div>
        )}

        {/* Contenedor del modal */}
        <div id="modal-aviso-pay" className="modal-aviso-pay" style={{ display: "none", maxWidth: "627px" }}>
            <div className="modal-content">
                <div className="ico"></div>
                <div className="title">Instrucciones enviadas con éxito</div>
                <div className="text">Te enviamos un correo a <strong>[correo@ejemplo.com]</strong> con los siguientes pasos para completar tu pago.</div>
                <a href="" className="ButtonDarkMedium">ACEPTAR</a>
            </div>
        </div>
       
      </article>
    </section>
  );
};

export default Component;