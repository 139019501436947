import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BannerLegal } from "../../components/Images";
import { BannerLegalMov } from "../../components/Images";
import axios from '../../fetch/axios';
import { LanguageCurrencyContext } from "../../context/LanguageCurrencyContext";
import { useTranslation } from "../../hooks/useTranslation";

const Component = () => {
  const navigate = useNavigate();
  const { lang } = useContext(LanguageCurrencyContext);
  const [terminos, setTerminos] = useState(null);
  const { translate } = useTranslation();

  useEffect(() => {

    const fetchPoliticas = async () => {
      try {
        const response = await axios.get('/legal-texts/detail/1' + `?lang=` + lang);
        setTerminos(response.data?.data);
      } catch (error) {
        console.error('Error fetching Instagram posts', error);
      }
    };
    fetchPoliticas();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    
  }, [lang])

  return (
    <>
      <section className="ArticleStaticLegal ImageButton pt-tophead">
        <article className="ImageButtonBG" >
          <picture>
            <source media="(max-width:700px)" srcSet={BannerLegalMov} type="image/webp" />
            <img src={BannerLegal} alt="" />
          </picture>
          <div>
            <div>
            <div className="ButtonContent ButtonDark">{terminos && terminos?.name} </div>
            </div>
          </div>
        </article>
        <article className="ArticleStaticContent">
          <div className="ArticleStaticContentText">
            {/* <strong>Arte On U</strong><br /><br />
            <strong>{terminos && terminos?.name}</strong><br /> */}
            {terminos && (
              <div dangerouslySetInnerHTML={{ __html: terminos?.content }} />
            )}          
          </div>
          <div className="Contactanos">
            <div>
              <b>{translate('legal.dudas')}</b>
            </div>
            <div>{translate('legal.description')}</div>
            <div
              className="ButtonContent ButtonDark"
              onClick={() => navigate("/contacto")}
            >
              {translate('legal.contact')}
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Component;
