import "./styles/Main.scss";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
/**
 *
 *
 *  ?  Routes
 *
 *
 */
import AppRoutes from "./components/Routes";
/**
 *
 *
 *  ?  Mail
 *
 *
 */
import MailingConfirmacion from "./pages/static/mail/MailingConfirmacion";
import RestablecerPassword from "./pages/static/mail/RestablecerPassword";
import { TokenProvider } from "./context/TokenContext";

function App() {
  //<Home />
  const Pages = (json) => {
    if (json.isall) {
      return (
        <Router>
          <ScrollToTop />
          
          <TokenProvider>

            <AppRoutes />

          </TokenProvider>

        </Router>
      );
    } else {
      return json.ismail;
    }
  };
  const pagesmail = {
    MailingConfirmacion: <MailingConfirmacion />,
    RestablecerPassword: <RestablecerPassword />,
  };
  /**
   *
   ** show HomeAll (ComingSoon, HomeIndex, Auth) page -> isall: true
   *
   *! en paginas de correo es requerido
   *! el estilo en linea de cada elemento
   *
   ** show mail's page    -> ismail: pagesmail.{}
   *
   */
  return Pages({
    isall: true,
    ismail: pagesmail.RestablecerPassword,
  });
}

export default App;
