// src/components/Footer.js
import React, { useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "../hooks/useTranslation";
import { LanguageCurrencyContext } from "../context/LanguageCurrencyContext";

const Component = () => {
  const navigate = useNavigate();
  const {translate}  = useTranslation();
  const { lang } = useContext(LanguageCurrencyContext);
  
  useEffect(()=>{
  },[lang])

  return (
    <footer>
      <div className="FooterFirst">
        <div className="ImageIcon ArtOnU" onClick={() => navigate("/")}></div>
        <div className="FooterLinks">
          <div className="TextLeft">
            <div>
              <h5>{translate('footer.term9')}</h5>
              <Link to="/terminos-y-condiciones">{translate('footer.term1')}</Link>
              <Link to="/terminos-de-servicios">{translate('footer.term2')}</Link>
              <Link to="/terminos-de-privacidad">{translate('footer.term3')}</Link>
            </div>
            <div>
              <Link to="/cambios-y-devoluciones">{translate('footer.term4')}</Link>
              <Link to="/tabla-de-tallas">{translate('footer.term5')}</Link>
              <Link to="/envios">{translate('footer.term6')}</Link>
              <Link to="/cookies">{translate('footer.term7')}</Link>
            </div>
          </div>
        </div>
        <div className="FooterSocial">
          <a href="https://www.facebook.com/arteonu/" target="_blank" className="icon-Facebook"></a>
          <a href="https://www.instagram.com/arteonu/" target="_blank" className="icon-Instagram"></a>
          {/* <a href="/" target="_blank" className="icon-Tiktok"></a>
          <a href="/" target="_blank" className="icon-X"></a> */}
        </div>
      </div>
      <div>© ArteOnU - {translate('footer.term8')}</div>
    </footer>
  );
};

export default Component;
