

import React from 'react'

function LoadingCardDetail() {
    return (
        <>
          <div className="col-md-3">
            <div className="wrapper">
              <div className="card-loader card-loader--tabs"></div>
            </div>
          </div>
        </>
      );
}

export default LoadingCardDetail

